import React from 'react';
import Slider from "react-slick";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlidSlick = (props) => {
        
    let showCount=1;
    let slideScrol=1;
    // if(isMobile){
if (typeof window !== 'undefined') {
    if(window.innerWidth<=767){
        showCount=1;
        slideScrol=1;
    }else if(window.innerWidth<=1024){
        if(props.banner === true){
            showCount=1;
            slideScrol=1;
        }else{
            showCount=2;
            slideScrol=2;            
        }

    }else{
            showCount=props.slidesToShow
            slideScrol=props.slidesToScrol    
    }
}
    const settings = {
        slidesToShow: showCount,
        slidesToScrol:slideScrol,
        arrows: props.arrow?props.arrow:true,
        swipeToSlide: false,
        focusOnSelect: false,
        name: props.id,
        autoplay:props.autoplay?true: false,
        autoplaySpeed: props.autoplay?3000:null,
        id: props.id,
        infinite: true,
        dots: props.dots !== undefined ? props.dots : false
    };
    return (
        <Slider
            {...settings}
        >
            {props.children}
        </Slider>
    )
}
export default SlidSlick