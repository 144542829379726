import React from 'react';
import Image from '../../atom/image';
import NextImage from '../../atom/nextImage';
import Para from '../../atom/paragraph/aboutpara';
import Slider from '../../atom/slider/slider';
import transperancyImg from "../../../assets/images/pmsImg/Transparency.png";
import segregatedPortfoliosImg from "../../../assets/images/pmsImg/Segregatedportfolios.png";
import dedicatedRMsImg from "../../../assets/images/pmsImg/DedicatedRMs.png";

const Keypms = (props) => {
    return (

        <div className="sliderCus">
            <div className="col-md-12 text-right">
        <div className="swipe-section d-inline-block d-sm-none"><span className="swipe-txt">SWIPE <Image image="/assets/images/landing/swipe-icon.png" className="img-responsive" /></span>
        </div>
        </div>

            <Slider slidesToShow={3} id="list1" slidesToScrol={2}>



                <div className="col-md-12">
                    
                    <div className="key-box">
                        {/* <Image image="../assets/images/pmsImg/Transparency.png" class="keyBenefits-icon" /> */}
                        <div className="keyBenefits-icon">
                            <NextImage
                                image={transperancyImg}
                                // layout={'fill'}
                                width={81}
                                height={81}
                            />
                        </div>
                        <h4>Transparency </h4>
                        <Para title="in portfolio holdings, transactions, and expenses." />

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="key-box ">
                        {/* <Image image="../assets/images/pmsImg/Segregatedportfolios.png" class="keyBenefits-icon" /> */}
                        <div className="keyBenefits-icon">
                            <NextImage
                                image={segregatedPortfoliosImg}
                                // layout={'fill'}
                                width={81}
                                height={81}
                            />
                        </div>

                        <h4>Segregated portfolios </h4>
                        <Para title="to ensure that individual investor behavior doesn't impact other's portfolio." />

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="key-box ">
                        {/* <Image image="../assets/images/pmsImg/DedicatedRMs.png" class="keyBenefits-icon" /> */}
                        <div className="keyBenefits-icon">
                            <NextImage
                                image={dedicatedRMsImg}
                                // layout={'fill'}
                                width={81}
                                height={81}
                            />
                        </div>

                        <h4>Dedicated RMs</h4>
                        <Para title="for your account and centralized team of service coordinators for hassle-free servicing" />

                    </div>
                </div>
                {/* <div className="col-md-12">
                    <div className="key-box ">
                        <Image image="/assets/images/pmsImg/DedicatedRMs.png" class="keyBenefits-icon" />

                        <h4>Dedicated RMs</h4>
                        <Para title="for your account and centralized team of service coordinators for hassle-free servicing" />

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="key-box ">
                        <Image image="/assets/images/pmsImg/DedicatedRMs.png" class="keyBenefits-icon" />

                        <h4>Dedicated RMs</h4>
                        <Para title="for your account and centralized team of service coordinators for hassle-free servicing" />

                    </div>
                </div> */}

            </Slider>

        </div >

    )
}

export default Keypms