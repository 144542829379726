import React from 'react'


const ViewAll = (props) => {
    if(props.newTab){
        return (
            <a target={`_blank`} href={props.url} className={`viewAllbtn ${props.class}`}>
                {props.title !== undefined ? props.title : "View All"}
            </a>
        );
    }else{
        return (
            <a href={props.url} className={`viewAllbtn ${props.class}`}>
                {props.title !== undefined ? props.title : "View All"}
            </a>
        );
    }
    
};
export default ViewAll