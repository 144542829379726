import React from 'react';
import renderHTML from 'react-render-html';

const HeadingH3 = (props) => {
    let title = props.title;
    return (
        // <h1 className={`headingH1  ${props.class}`} title={props.title}>
        //     {props.title}

        // </h1>
        <h3
            style={{ ...props.style }}

            className={`headingH1 ${props.class}`}
             dangerouslySetInnerHTML={{
             __html: title,
         }}
        >
            {/* {renderHTML(title)} */}
        </h3>
    )
}


export default HeadingH3
