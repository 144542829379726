import React from 'react';
import Aux from '../children/index';

const NotMsg = (props) => {
    return (
        <Aux>
            <p className={`alert alert-danger mt-2 ${props.class}`}>
                {props.msg}
            </p>
        </Aux>
    )
}
export default NotMsg