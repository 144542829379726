import React from 'react';
import Para from '../../atom/paragraph/aboutpara';
import Router from 'next/router'
import Image from '../../atom/image';
import Link from 'next/link';
import NextImage from '../../atom/nextImage';

const PmsList = (props) => {
    let { schcode } = props.allData;
    const knowMoreRedire = (url) => {
        // let geturl=`/mutual-funds/${url}`;
        // console.log(geturl,'geturl')
        // Router.push(`/mutual-funds/${url}`)
        // window.location.href=`/mutual-funds/${url}`;
    }

    // let newSchCode = props.allData.schcode;
    let newSchCode = props.allData.schnameURL;
    // let schUrl = '/pageDetail/'+newSchCode;
    let schUrl = '/our-strategies/' + newSchCode;

    let url = ""

    const clickHandler = (code) => {
        // window.location.href=`/pageDetail/${code}`;
        window.location.href = `/our-strategies/${code}`;
        //Router.push(`/pageDetail/${code}`)
    }


    return (
        <div className="col-md-12">
            <div className={`row minheight pmsStr ${props.headClass} paddingBox box_shadow`}>
                <div className="col-8 col-md-8 col-lg-8">
                    <h3 className="portfolioHeading">{props.title}</h3>
                </div>
                <div className="col-4 col-md-4 col-lg-4">
                    {props.image !== "" ?
                        <Image image={props.image} class="pmsRightIcon" fullPath={true} />
                        // <div className="pmsRightIcon">
                        //     <NextImage
                        //         image={props.image}
                        //         // image={'https://mf.moamc.com/CMS/assets/uploads/PreLoginImages/MotilalOswalValueStrategy.png'}
                        //         alt={props.title}
                        //         width={87}
                        //         height={70}
                        //     />
                        // </div>
                        : null}
                </div>
                <div className="col-md-12">
                    <p class="portfolioInformation">{props.desc}</p>
                </div>
                {props.btn ?
                    <div className="col-md-12">
                        <a onClick={() => clickHandler(newSchCode)} className="informationButton">Know more
                            <Image image="../assets/images/landing/rightarrow.svg" class="" style={{display: 'inline', verticalAlign: 'middle', height: 'auto', width: 'auto', 'margin-left': '4px' }} />
                            {/* <NextImage
                                image={'/assets/images/landing/rightarrow.svg'}                                
                                alt={'Know more icon'}
                                width={100}
                                height={100}
                            /> */}
                        </a>
                    </div> : null}
            </div>
        </div>
    )
}

export default PmsList;