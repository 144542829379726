import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NextImage from "../../atom/nextImage";
import Slider from '../../atom/slider/slider';

const delay = process.env.NEXT_PUBLIC_BANNER_LOADING_DELAY;

const BannerWrapper = (props) => {
  const [isMobile, setIsMobile] = useState(false)
  let NewBannerMobileSlider = props?.data?.NewBannerMobileSlider || [];
  let NewBannerSlider = props?.data?.NewBannerSlider || [];

  const isOnServer = typeof window === 'undefined';
  const [showAllBanner, setShowAllBanner] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAllBanner(true);
    }, delay);
  }, [])

  const mobileQuery = useMediaQuery('(max-width:768px)');
  useEffect(() => {
    setIsMobile(mobileQuery);
  }, [mobileQuery]); 

  let mobileSliderImages = NewBannerMobileSlider;
  let desktopSliderImages = NewBannerSlider;

  // if(isOnServer){
  if (!showAllBanner) {
    if (NewBannerMobileSlider.length > 0) {
      mobileSliderImages = [NewBannerMobileSlider[0]];
    }
    if (NewBannerSlider.length > 0) {
      desktopSliderImages = [NewBannerSlider[0]];
    }
  }

  let mobileBanner = (
    <div key={'mob'} id={'mob'} className="slider_dots hideR mob">
      {/* <React.Fragment> */}
      {NewBannerMobileSlider.length > 0 ? (
        <Slider
          autoplay={true}
          slidesToShow={1}
          arrow={false}
          slidesToScrol={1}
          dots={true}
          banner={true}
        >
          {mobileSliderImages.map((curr, index) => {
            return (
              <div key={index}>
                <a href={curr.link} id="home-mobile-slider-image">
                  <NextImage
                    image={curr.image}
                    alt={"mob-banner" + index}
                    width={720}
                    height={540}
                    priority={true}
                  />
                </a>
              </div>
            );
          })}
        </Slider>
      ) : null}
      {/* </React.Fragment> */}
    </div>
  );

  let newDesktopBanner = (
    <div key={'des'} id={'des'} className="slider_dots hideR des">
      {NewBannerSlider.length > 0 ? (
        <Slider
          slidesToShow={1}
          autoplay={true}
          arrow={false}
          slidesToScrol={1}
          dots={true}
          banner={true}
        >
          {
            desktopSliderImages.map((curr, index) => {
              return (
                <div key={index}>
                  <a href={curr.link} target="_blank">
                    {/* <Image image={curr.image} alt="banner1" class="sliderImg" /> */}
                    <NextImage
                      image={curr.image}
                      alt={"banner" + index}
                      width={1920}
                      height={440}
                      // priority={true}
                      loading="lazy"
                    />
                  </a>
                </div>
              );
            })
          }
        </Slider>
      ) : null}
    </div>
  )

  return (<div key={'place'} id={'pl'} className={isMobile ? 'mobi' : 'deski'}>
    <div className='desk-ban'>{newDesktopBanner}</div>
    <div className='mob-ban'>{mobileBanner}</div>
  </div>);
};
export default BannerWrapper;
