import { useState, useEffect } from 'react';

import renderHTML from 'react-render-html';
const AboutPara = (props) => {
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, []);

  if (!isClient) return <></>

  return (
    <p className={`paraRegular ${props.class}`} style={{ ...props.style }}>
      {renderHTML(props.title)}
    </p>
  )
}
export default AboutPara;