import React, {useState, useEffect} from 'react';
import PMSList from '../../molecules/PMSList';
import Image from '../../atom/image';
import Slider from '../../atom/slider/slider';
import ViewAll from '../../atom/link/viewAll'
import Loader from '../../atom/loader/pageLaoder';
import ApiCall from '../../../services/preLoginApi';

let api = new ApiCall();

const PMSBox = (props) => {
    
    let apiData = props.apiData;
    
    if(apiData.length===0){
        return <Loader/>
    }

    let displayData=null;

    if(apiData.length>0){
      displayData = apiData.map((curr, index) => {
            let classBg="";
            if(index===0){
                classBg="first-tab"
            }
            if(index===1){
                classBg="second-tab"
            }
            if(index===2){
                classBg="third-tab"
            }
            if(index===3){
                classBg="fourth-tab"
            }
            if(index===4){
                classBg="fifth-tab"
            }
            return(
              <div key={index} className="col-md-12">
                    <PMSList
                        headClass={classBg!==""?classBg:"first-tab"}
                        title={curr.schname}
                        image={curr.StrategyIcon}
                        slideWidth="col-md-12"
                        desc={curr.StrategyDesc}
                        btn={true}
                        allData={curr}
                        url="pageDetail" 
                    />
                </div>
            )
        })
    }

    return (
        <div className="sliderCus">
        <div className="col-md-12 text-right">
        <div className="swipe-section d-inline-block d-sm-none"><span className="swipe-txt">SWIPE <Image image="/assets/images/landing/swipe-icon.png" className="img-responsive" /></span>
        </div>
          <ViewAll url="/our-strategies" />
        </div>
           {displayData!==null? 
            <Slider slidesToShow={props.max} slidesToScrol={props.min}>
                {displayData}
            </Slider>:null}
        </div>
    )
}

export default PMSBox;