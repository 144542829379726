import React, { useState } from 'react';
import Aux from '../../atom/children';
import renderHTML from 'react-render-html';


export default function Accordian(props) {
    let allData = props.data;
    const [state, setState] = useState({ value: "" });
    const click = (data) => {
        if (data === state.value) {
            setState({ value: "" })
        } else {
            setState({ value: data })
        }

    }

    return (
        <Aux>

            {allData.map((curr, index) => {
                return (
                    <div className={`custAccord box_shadow ${state.value === index ? "custAccord_Open" : null}`} key={index}>
                        <h3 onClick={() => click(index)}>{curr.question} <span className="colArrow"></span></h3>
                        <div className="innerAccord">
                            {renderHTML(curr.answer)}
                        </div>
                    </div>
                )
            })}

        </Aux>
    )
}
